const dynamicValueTypes = {
  broker_driver_pay_rollup: [
    'driver_ledger_code',
    'pay_rollup_date',
    'pay_rollup_total',
  ],

  invoice: [
    'customer_ledger_code',
    'invoice_number',
    'invoice_date',
    'invoice_total',
  ],
  revenue: [
    'ticket_type',
    'ticket_date',
    'ticket_number',
    'customer_name',
    'item_name',
    'dropoff_site_name',
    'job_name',
    'quantity',
    'rate',
    'lot_number',
    'po_number',
    'description',
    'is_flat_rate',
    'is_taxable',
    'is_convey',
  ],
}

const dynamicValueTypeChoices = Object.keys(dynamicValueTypes).reduce((accum, type) => {
  return {
    ...accum,
    [type]: dynamicValueTypes[type].map(id => ({
      id,
      name: `resources.export_fields.dynamic_value_type.${type}.${id}`,
    }))
  }
}, {})

export {
  dynamicValueTypes,
  dynamicValueTypeChoices,
}
