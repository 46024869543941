import {
  TextInput,
} from 'react-admin'
import { Box } from '@material-ui/core'
import {
  formInputOptions,
} from '../../util/component-options'
import { useFormStyles } from '../../../styles'
import { useLabel, useTranslateResource } from '../../../hooks'
import { Form, getValidator } from '../../form'
import { AddressInputFields } from '../../shared'
import { NumberInput } from '../../custom'

const TenantsForm = props => {
  const { resource } = props
  const formClasses = useFormStyles(props)
  const label = useLabel(props)
  const translate = useTranslateResource(resource)

  return (
    <Form
      {...props}
    >
      <Box className={formClasses.grid}>
        <Box>
          <TextInput
            {...formInputOptions}
            source='name'
            label={label('name')}
            validate={getValidator()}
            required
          />

          <NumberInput
            {...formInputOptions}
            source='minimum_hourly_wage_in_dollars'
            label={label('minimum_hourly_wage_in_dollars')}
            step={0.01}
            min={0}
            validate={getValidator(false)}
          />

          <NumberInput
            {...formInputOptions}
            source='sales_tax_rate'
            label={label('sales_tax_rate')}
            step={0.001}
            min={0}
            validate={getValidator(false)}
            helperText={translate('form.salesTaxRateHelperText')}
          />

          <NumberInput
            {...formInputOptions}
            source='default_convey_rate_in_dollars'
            label={label('default_convey_rate_in_dollars')}
            step={0.01}
            min={0}
            validate={getValidator(false)}
          />
        </Box>
        <Box>
          <AddressInputFields resource={resource} includeCounty />
        </Box>
      </Box>
    </Form>
  )
}

export default TenantsForm
