import { useCallback } from 'react'
import {
  HttpError,
} from 'react-admin'
import { getApiUrl } from '../http-client'
import { getFilterQuery } from '../json-provider/util'

const useDownload = (resource, path, filters = {}, params = {}) => {
  return useCallback(async () => {
    const filtersQuery = getFilterQuery(filters, resource)
    const query = {
      ...filtersQuery,
      ...params,
    }
    const fileUrl = getApiUrl(path, { query })
    const fileResponse = await fetch(fileUrl, { credentials: 'include' })

    const { status, statusText, headers } = fileResponse

    if (status >= 300) throw new HttpError(statusText, status)

    if (status !== 204) { // no content
      const contentDisposition = headers.get('content-disposition')
      const filenameRegex = /filename="(?<filename>.*)"/
      const filename = contentDisposition.match(filenameRegex)?.groups.filename ?? null
      const blob = await fileResponse.blob()
      const anchor = document.createElement('a')
      const objectUrl = window.URL.createObjectURL(blob)
      anchor.href = objectUrl
      anchor.download = filename
      document.body.appendChild(anchor)
      anchor.click()
      document.body.removeChild(anchor)
      window.URL.revokeObjectURL(objectUrl)
    }

    return status
  }, [resource, path, filters, params])
}

export default useDownload
