import { useCallback, useMemo, useState } from 'react'
import { useListContext, useNotify } from 'react-admin'
import { useTranslateResource, useDownload } from '../../hooks'
import { Button, Grid, CircularProgress } from '@material-ui/core'

const DownloadButton = () => {
  const { basePath, filterValues, resource, loading, total } = useListContext()
  const [isDownloading, setIsDownloading] = useState(false)
  const translate = useTranslateResource(resource)
  const notify = useNotify()

  const filter = useMemo(() => {
    return {
      filter: filterValues,
    }
  }, [filterValues])

  const query = useMemo(() => {
    const tzOffset = -new Date().getTimezoneOffset() / 60
    return {
      timezone_offset: tzOffset,
    }
  }, [])

  const download = useDownload(resource, `${basePath}.zip`, filter, query)

  const isDisabled = useMemo(() => {
    return isDownloading || loading || !total
  }, [isDownloading, loading, total])

  const onDownloadClick = useCallback(async () => {
    setIsDownloading(true)
    try {
      const status = await download()
      if (status === 204) {
        notify(`resources.${resource}.list.downloadEmpty`, 'info')
      }
    } catch(error) {
      notify(`resources.${resource}.list.downloadError`, 'error')
    }
    setIsDownloading(false)
  }, [download, notify, resource])

  return (
    <Grid item>
      <Button
        onClick={onDownloadClick}
        variant='contained'
        color='primary'
        size='small'
        disabled={isDisabled}
      >
        {
          isDownloading ?
            <CircularProgress size={24} style={{margin: '0 27.5px'}}/> :
            translate('list.download')
        }
      </Button>
    </Grid>
  )
}

export default DownloadButton
