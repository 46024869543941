import {
  TextField,
  DateField,
  TextInput,
  Link,
  FunctionField,
} from 'react-admin'
import { DateInput, Datagrid, List, Filter } from '../../custom'
import { dateTimeFieldOptions } from '../../util/component-options'
import { useLabel } from '../../../hooks'
import DownloadButton from '../../shared/download-button'

const TonnageTicketUploadsFilter = props => {
  const label = useLabel(props)

  return (
    <Filter>
      <TextInput label={label('ticket_image_filename', 'find')} source='ticket_image_blob_filename_i_cont' />
      <TextInput label={label('sender', 'filter')} source='sender_phone_eq' />
      <DateInput
        label={label('created_at', 'start')}
        source='created_at_gteq'
      />
      <DateInput
        label={label('created_at', 'end')}
        source='created_at_lt'
      />
    </Filter>
  )
}

const TonnageTicketUploadsList = props => {
  const label = useLabel(props)

  return (
    <List
      {...props}
      filters={<TonnageTicketUploadsFilter />}
      actions={<DownloadButton />}
    >
      <Datagrid
        hasEdit
      >
        <TextField source='ticket_image_filename' label={label('ticket_image_filename')} sortBy='ticket_image_blob_filename' />
        <TextField source='sender.phone' label={label('sender')} sortBy='sender_phone' />
        <FunctionField label={label('tonnage_ticket_pdf_upload')} sortBy={'tonnage_ticket_pdf_upload_pdf_file_blob_filename'} render={record => {
          const { tonnage_ticket_pdf_upload } = record
          return (
            tonnage_ticket_pdf_upload &&
            <Link to={`/tonnage_ticket_pdf_uploads/${tonnage_ticket_pdf_upload.id}`}>
              { tonnage_ticket_pdf_upload.pdf_filename }
            </Link>
          )
        }} />
        <DateField source='created_at' label={label('created_at')} options={dateTimeFieldOptions}/>
      </Datagrid>
    </List>
  )
}

export default TonnageTicketUploadsList
