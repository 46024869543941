import {
  useCallback,
  useState,
  useEffect,
  useMemo,
} from 'react'
import {
  ReferenceInput,
  TextInput,
  useDataProvider,
  Loading,
} from 'react-admin'
import { Box } from '@material-ui/core'
import get from 'lodash/get'
import { Autocomplete, DateInput, NumberInput, BooleanInput } from '../../custom'
import {
  referenceInputOptions,
  formInputOptions,
} from '../../util/component-options'
import { useFormStyles } from '../../../styles'
import {
  useLabel,
  useTranslateResource,
  useTenant,
} from '../../../hooks'
import { relationships } from '../../../data-model'
import { Form, TruckDriverFields, getValidator } from '../../form'
import {
  useTonnageRateFields,
  useTonnageRateReferenceFields,
  useReferenceLookup,
  useAutofillFields,
} from '../../form/functions'
import { useFormState } from 'react-final-form'
import { pick } from 'lodash'

const validate = getValidator()

const tonnageRateReferenceInputOptions = {
  sort: {
    field: 'code',
    order: 'ASC',
  },
}

const skipTabInputProps = {
  tabIndex: '-1',
}

const baseAutofillRateFields = [
  'base_rate_in_dollars',
  'fuel_surcharge_in_dollars',
  'material_surcharge_in_dollars',
  'is_flat_haul_rates',
  'is_flat_material_sale_price',
  'driver_pay_in_dollars',
]

const materialSalePriceAutofillFields = [
  'material_sale_price_in_dollars',
]

const equipmentRateAutofillFields = [
  'equipment_rate_in_dollars',
]

const FormBody = props => {
  const { onRateChange, tenant, ...restProps } = props
  const { resource, record } = restProps
  const extraction = record.tonnage_ticket_extraction
  const isNewRecord = !record.id || Boolean(extraction)
  const resourceRelationships = relationships[resource]
  const formClasses = useFormStyles(restProps)
  const dataProvider = useDataProvider()
  const [impliedRate, setImpliedRate] = useState()
  const rateFields = useTonnageRateFields()
  const rateLookupReferenceFields = useTonnageRateReferenceFields()
  const label = useLabel(restProps)
  const translate = useTranslateResource(resource, 'form')
  const { selectedRecord: selectedRate, onSelectedIdChange: onSelectedRateIdChange } = useReferenceLookup(resourceRelationships['tonnage_rate'], record.tonnage_rate)
  const { selectedRecord: selectedTruck, onSelectedIdChange: onSelectedTruckIdChange } = useReferenceLookup(resourceRelationships['truck'], record.truck)
  const [materialSalePrice, setMaterialSalePrice] = useState(null)
  const formState = useFormState()
  const materialId = get(formState.values, 'material.id')
  const pickupSiteId = get(formState.values, 'pickup_site.id')
  const truckIsConveyor = selectedTruck?.is_conveyor ?? false

  const autofillRateReferenceFields = useMemo(() => {
    return rateLookupReferenceFields.map(f => `${f.source}.id`)
  }, [rateLookupReferenceFields])

  const rate = useMemo(() => {
    return selectedRate || impliedRate
  }, [impliedRate, selectedRate])

  const shouldAutofillRateReferenceField = useCallback(() => {
    return isNewRecord
  }, [isNewRecord])

  const shouldAutofillBaseRateField = useCallback(() => {
    return isNewRecord
  }, [isNewRecord])

  const shouldAutofillDriverField = useCallback(() => {
    return isNewRecord
  }, [isNewRecord])

  const shouldAutofillMaterialSalePriceField = useCallback(() => {
    return isNewRecord
  }, [isNewRecord])

  const shouldAutofillEquipmentRateField = useCallback(() => {
    return isNewRecord
  }, [isNewRecord])

  const baseRateFieldAutofillSource = useMemo(() => {
    if (!rate) return null
    return pick(rate, baseAutofillRateFields)
  }, [rate])

  const materialSalePriceAutofillSource = useMemo(() => {
    return {
      material_sale_price_in_dollars: rate?.material_sale_price_in_dollars ?? materialSalePrice,
    }
  }, [rate, materialSalePrice])

  const equipmentRateAutofillSource = useMemo(() => {
    return {
      equipment_rate_in_dollars: truckIsConveyor ? (rate?.equipment_rate_in_dollars ?? tenant?.default_convey_rate_in_dollars) : null,
    }
  }, [rate, tenant, truckIsConveyor])

  const [
    getRateReferenceFieldIsAutofilled,
    onAutofillRateReferenceFieldChange,
   ] = useAutofillFields(autofillRateReferenceFields, selectedRate, shouldAutofillRateReferenceField)

   const [
    getBaseRateFieldIsAutofilled,
    onBaseAutofillRateFieldChange,
   ]= useAutofillFields(baseAutofillRateFields, baseRateFieldAutofillSource, shouldAutofillBaseRateField)

   const [
    getMaterialSalePriceIsAutofilled,
    onMaterialSalePriceFieldChange,
   ]= useAutofillFields(materialSalePriceAutofillFields, materialSalePriceAutofillSource, shouldAutofillMaterialSalePriceField)

   const [
    getEquipmentRateIsAutofilled,
    onEquipmentRateFieldChange,
   ]= useAutofillFields(equipmentRateAutofillFields, equipmentRateAutofillSource, shouldAutofillEquipmentRateField)

  const [rateReferenceFieldValues, setRateReferenceValues] = useState(() => {
    const { record } = props
    return rateLookupReferenceFields.reduce((accum, { source }) => {
      return {
        ...accum,
        [source]: get(record, [source, 'id']),
      }
    }, {})
  })

  const onRateLookupFieldChange = useCallback((source, value) => {
    setRateReferenceValues(prev => ({
      ...prev,
      [source]: value,
    }))
    onAutofillRateReferenceFieldChange(`${source}.id`, value)
  }, [onAutofillRateReferenceFieldChange])

  const onRateFieldChange = useCallback((source, value) => {
    switch (source) {
      case 'equipment_rate_in_dollars':
        onEquipmentRateFieldChange(source, value)
        break
      case 'material_sale_price_in_dollars':
        onMaterialSalePriceFieldChange(source, value)
        break
      default:
        onBaseAutofillRateFieldChange(source, value)
    }
  }, [onMaterialSalePriceFieldChange, onBaseAutofillRateFieldChange, onEquipmentRateFieldChange])

  const getRateFieldIsAutofilled = useCallback(field => {
    switch (field) {
      case 'equipment_rate_in_dollars':
        return getEquipmentRateIsAutofilled(field)
      case 'material_sale_price_in_dollars':
        return getMaterialSalePriceIsAutofilled(field)
      default:
        return getBaseRateFieldIsAutofilled(field)
    }
  }, [getBaseRateFieldIsAutofilled, getMaterialSalePriceIsAutofilled, getEquipmentRateIsAutofilled])

  const getSkipTabForRateField = useCallback(field => {
    const value = get(formState.values, field, truckIsConveyor)
    switch (field) {
      case 'equipment_rate_in_dollars':
        return !truckIsConveyor || (value != null && truckIsConveyor && getEquipmentRateIsAutofilled(field))
      case 'material_sale_price_in_dollars':
        return value != null && getMaterialSalePriceIsAutofilled(field)
      default:
        return value != null && getBaseRateFieldIsAutofilled(field)
    }
  }, [formState, truckIsConveyor, getBaseRateFieldIsAutofilled, getMaterialSalePriceIsAutofilled, getEquipmentRateIsAutofilled])

  const getRateFieldHelperText = useCallback(field => {
    const rateValue = get(rate, field)
    const autofilled = getRateFieldIsAutofilled(field)

    if (field === 'material_sale_price_in_dollars') {
      if (rateValue == null && materialSalePrice == null) {
        return translate('general.noValue')
      } else if (rateValue == null && materialSalePrice != null) {
        if (autofilled) {
          return translate('general.materialSalePrice.autofilled', { value: materialSalePrice })
        } else {
          return translate('general.materialSalePrice.current', { value: materialSalePrice })
        }
      }
    }

    if (field === 'equipment_rate_in_dollars') {
      const defaultConveyRate = tenant?.default_convey_rate_in_dollars
      if (!truckIsConveyor) {
        return translate('general.notEquipmentTruck')
      }
      if (rateValue == null && defaultConveyRate == null) {
        return translate('general.noValue')
      } else if (rateValue == null && defaultConveyRate != null) {
        if (autofilled) {
          return translate('general.rate.autofilledFromDefault', { value: defaultConveyRate })
        } else {
          return translate('general.rate.currentFromDefault', { value: defaultConveyRate })
        }
      }
    }

    if (!rate) {
      return translate('general.noRate')
    } else {
      if (rateValue == null) {
        return translate('general.rate.autofilledBlank')
      } else {
        if (autofilled) {
          return translate('general.rate.autofilled', { value: rateValue })
        } else {
          return translate('general.rate.current', { value: rateValue })
        }
      }
    }
  }, [rate, translate, getRateFieldIsAutofilled, materialSalePrice, tenant, truckIsConveyor])

  const selectedRateText = useMemo(() => {
    let text = translate('general.noRate')
    if (rate) {
      if (rate.code) {
        text = translate('general.selectedRateWithCode', {
          code: rate.code,
        })
      } else {
        text = translate('general.selectedRateWithoutCode', {
          description: rate.description,
        })
      }
    }
    return rate && !rate.is_active ? [text, `(${translate('general.inactiveRate')})`].join(' ') : text
  }, [rate, translate])

  const getExtractedValueHelperText = useCallback(field => {
    if (extraction) {
      const value = extraction[field]
      if (value) {
        return translate('edit.extractedText', { value })
      }
    }
  }, [extraction, translate])

  const getRateReferenceFieldHelperText = useCallback(field => {
    const texts = []
    texts.push(getExtractedValueHelperText(field.replace(/_id$/, '')))
    if (rate) {
      const value = get(rate, [field, 'name'])
      if (value) {
        const autofilled = getRateReferenceFieldIsAutofilled(`${field}.id`)
        texts.push(translate(`general.rate.${autofilled ? 'autofilled' : 'current'}`, { value }))
      }
    }
    const result = texts.filter(Boolean).join('\n')
    return result.length ? result : null
  }, [rate, getRateReferenceFieldIsAutofilled, translate, getExtractedValueHelperText])

  const validateTonnage = useMemo(() => {
    const max = 999
    return getValidator(true, [
      value => value > max ? translate('validate.tonnageTooHigh', { max }) : null,
    ])
  }, [translate])

  useEffect(() => {
    if (!materialId || !pickupSiteId) {
      setMaterialSalePrice(null)
    } else {
      (async () => {
        const filter = {
          material_id_eq: materialId,
          pickup_site_id_eq: pickupSiteId,
        }
        const pagination = {
          page: 1,
          perPage: 1,
        }
        const responseData = await dataProvider.getList('material_sale_prices', { filter, pagination })
        const lookupValue = get(responseData, 'data[0].price_in_dollars')
        if (lookupValue) {
          setMaterialSalePrice(lookupValue)
        } else {
          setMaterialSalePrice(null)
        }
      })()
    }
  }, [materialId, pickupSiteId, dataProvider])

  useEffect(() => {
    if (selectedRate) return
    const emptyValuesCount = Object.values(rateReferenceFieldValues).filter(v => !v).length
    if (emptyValuesCount > 0) {
      setImpliedRate()
      return
    }

    (async () => {
      const filter = {
        is_active_eq: true,
        ...Object.entries(rateReferenceFieldValues).reduce((accum, [field, value]) => {
          return {
            ...accum,
            [`${field}_id_eq`]: value,
          }
        }, {}),
      }
      const pagination = {
        page: 1,
        perPage: 1,
      }
      const responseData = await dataProvider.getList('tonnage_rates', { filter, pagination })
      setImpliedRate(responseData.data[0])
    })()
  }, [rateReferenceFieldValues, selectedRate, dataProvider])

  useEffect(() => {
    onRateChange(rate)
  }, [rate, onRateChange])

  const rateFieldsBySource = useMemo(() => {
    return rateFields.reduce((accum, field) => ({ ...accum, [field.source]: field }), {})
  }, [rateFields])

  const rateReferenceFieldsBySource = useMemo(() => {
    return rateLookupReferenceFields.reduce((accum, field) => ({ ...accum, [field.source]: field }), {})
  }, [rateLookupReferenceFields])

  const renderRateReferenceField = ({ source, required, optionText, optionSubtext, label: _label }) => {
    const sourceWithId = `${source}.id`
    return (
      <ReferenceInput
        {...formInputOptions}
        {...referenceInputOptions(resourceRelationships[source])}
        key={source}
        source={sourceWithId}
        reference={resourceRelationships[source]}
        label={label(_label)}
        onChange={onRateLookupFieldChange.bind(null, source)}
        required={required}
        inputProps={{
          ...formInputOptions.inputProps,
          ...(getRateReferenceFieldIsAutofilled(sourceWithId) ? skipTabInputProps : undefined),
        }}
      >
        <Autocomplete
          validate={getValidator(required)}
          optionText={optionText}
          optionSubtext={optionSubtext}
          helperText={getRateReferenceFieldHelperText(source)}
        />
      </ReferenceInput>
    )
  }

  const renderRateField = ({ source, required, type, defaultValue, label: _label }) => {
    switch (type) {
      case 'number':
        return (
          <NumberInput
            {...formInputOptions}
            validate={getValidator(required)}
            key={source}
            source={source}
            label={label(_label)}
            step={0.01}
            min={0}
            required={required}
            onChange={onRateFieldChange.bind(null, source)}
            helperText={getRateFieldHelperText(source)}
            inputProps={{
              ...formInputOptions.inputProps,
              ...(getSkipTabForRateField(source) ? skipTabInputProps : undefined),
            }}
          />
        )
      case 'boolean':
        return (
          <BooleanInput
            source={source}
            key={source}
            label={label(_label)}
            defaultValue={defaultValue}
            tabIndex={-1}
            onChange={onRateFieldChange.bind(null, source)}
            helperText={getRateFieldHelperText(source)}
          />
        )
      default:
        return null
    }
  }

  return (
    <Box className={formClasses.grid}>
      <Box>
        <ReferenceInput
          {...formInputOptions}
          {...referenceInputOptions(resourceRelationships['tonnage_rate'])}
          {...tonnageRateReferenceInputOptions}
          source='tonnage_rate.id'
          reference={resourceRelationships['tonnage_rate']}
          label={translate('labels.tonnageRate')}
          onChange={onSelectedRateIdChange}
        >
          <Autocomplete
            optionText='code'
            optionSubtext='description'
            helperText={selectedRateText}
            matchFrom='start'
            autoFocus
          />
        </ReferenceInput>

        {renderRateReferenceField(rateReferenceFieldsBySource.customer)}
        {renderRateReferenceField(rateReferenceFieldsBySource.recipient)}
        {renderRateReferenceField(rateReferenceFieldsBySource.pickup_site)}
        {renderRateReferenceField(rateReferenceFieldsBySource.dropoff_site)}
        {renderRateReferenceField(rateReferenceFieldsBySource.material)}

        <TextInput
          {...formInputOptions}
          source='lot_number'
          label={label('lot_number')}
          validate={validate(false)}
        />

        <TextInput
          {...formInputOptions}
          source='po_number'
          label={label('po_number')}
          validate={validate(false)}
        />
      </Box>

      <Box>
        <TextInput
          {...formInputOptions}
          source='ticket_number'
          label={label('ticket_number')}
          validate={validate}
          required
        />

        <DateInput
          {...formInputOptions}
          validate={validate}
          source='worked_at_date'
          label={label('worked_at_date')}
          required
        />

        <NumberInput
          {...formInputOptions}
          source='net_weight_in_tons'
          label={label('net_weight_in_tons')}
          step={0.01}
          min={0}
          validate={validateTonnage}
          required
        />

        {renderRateField(rateFieldsBySource.base_rate_in_dollars)}
        {renderRateField(rateFieldsBySource.fuel_surcharge_in_dollars)}
        {renderRateField(rateFieldsBySource.material_surcharge_in_dollars)}
        {renderRateField(rateFieldsBySource.equipment_rate_in_dollars)}
        {renderRateField(rateFieldsBySource.material_sale_price_in_dollars)}
      </Box>

      <Box>
        <TruckDriverFields
          {...restProps}
          truckHelperText={getExtractedValueHelperText('truck_number')}
          shouldAutofill={shouldAutofillDriverField}
          onSelectedTruckChange={onSelectedTruckIdChange}
        />
        {renderRateField(rateFieldsBySource.driver_pay_in_dollars)}
        {renderRateField(rateFieldsBySource.is_flat_haul_rates)}
        {renderRateField(rateFieldsBySource.is_flat_material_sale_price)}
      </Box>
    </Box>
  )
}

// wrap component so we can use useForm hook
const TonnageTicketsForm = props => {
  const { resource, record, useOptimisticSave } = props
  const { tonnage_ticket_extraction: extraction } = record
  const [rate, setRate] = useState()
  const translate = useTranslateResource(resource, 'form')
  const isDisabled = Boolean(record.snapshot_mode)
  const tenant = useTenant()

  const clonedRecord = (() => {
    if (!record.id || Boolean(extraction) || isDisabled) return
    const { id, ...clonedRecordProps } = record
    return {
      ...clonedRecordProps,
      tonnage_rate: null, // rely on implied rate
      clone_id: id,
    }
  })()

  // Submit current tonnage_rate
  const transform = useCallback((data) => {
    return {
      ...data,
      tonnage_rate: {
        id: rate?.id,
      },
    }
  }, [rate])

  const getOptimisticFailureMessageContext = useCallback((data) => {
    return `#${data.ticket_number}`
  }, [])

  if (!tenant) return <Loading loadingSecondary={''} loadingPrimary={''} />

  return (
    <Form
      {...props}
      clonedRecord={clonedRecord}
      infoText={translate('info.formHelper')}
      transform={transform}
      useOptimisticSave={useOptimisticSave}
      getOptimisticFailureMessageContext={getOptimisticFailureMessageContext}
      isUpdateDisabled={isDisabled}
      isDeleteDisabled={isDisabled}
    >
      <FormBody
        {...props}
        onRateChange={setRate}
        tenant={tenant}
      />
    </Form>
  )
}

export default TonnageTicketsForm
